import Web3 from "web3";

// 初始化 Web3 实例，这里假设你已经有一个节点或提供商的 URL
const web3 = new Web3(new Web3.providers.HttpProvider('https://polygon.drpc.org'));

// 获取合约 ABI，通常你会从外部 JSON 文件或 API 获取
// 这里假设你有一个 ABI 的映射，例如 'pair' 对应的 ABI 已经加载
const contractABIs = {
  erc20: require('@/config/abi/erc20.json'),
  pair: require('@/config/abi/jackPair.json'), // 'pair' 合约的 ABI
  router: require('@/config/abi/jackRouter.json'), // 'router' 合约的 ABI
  factory: require('@/config/abi/jackFactory.json'), // 'factory' 合约的 ABI
  // 其他合约类型可以在这里添加
};

async function getContractInstance(contractType, contractAddress) {
    try {
      // 从已加载的 ABI 中获取合约的 ABI
      const contractABI = contractABIs[contractType];
      
      if (!contractABI) {
        throw new Error(`未找到合约类型 ${contractType} 的 ABI`);
      }
  
      // 创建并返回合约实例
      const contractInstance = new web3.eth.Contract(contractABI, contractAddress);
      return contractInstance;
    } catch (error) {
      console.error(`获取合约实例失败: ${error.message}`);
      return null;
    }
  }


  async function getIstokenOne(abiPairContractAddress, tokenAddress) {
    const pairContract = await getContractInstance('pair', abiPairContractAddress);
    const token0Fun = pairContract.methods.token0;
    const res = await token0Fun().call();
    const token0 = res.toString();
    return token0.toLowerCase() === tokenAddress;
  }
  async function getOtherToken(abiPairContractAddress, tokenAddress) {
    const pairContract = await getContractInstance('pair', abiPairContractAddress);
    
    // 获取 token0 和 token1
    const token0Fun = pairContract.methods.token0;
    const token1Fun = pairContract.methods.token1;
  
    const res1 = await token0Fun().call();
    const res2 = await token1Fun().call();
  
    const token0 = res1.toString();
    const token1 = res2.toString();
    // 根据 tokenAddress 返回另一个 token
    if (token0 === tokenAddress) {
      return token1;
    } else {
      return token0;
    }
  }

  async function  getCurrentPairPrice(
    abiContractAddressList, // Array of contract addresses
    tokenAddress, // Contract address of the first token
    decimalsToken0, // Decimals of the first token
    decimalsToken1 // Decimals of the second token
  ) {
    try {
      if (abiContractAddressList[0].toLowerCase() === tokenAddress.toLowerCase()) {
        return 1;
      }
  
      // If the array length is 1, get the price directly from the pool
      if (abiContractAddressList.length === 1) {
        const abiContractAddress = abiContractAddressList[0];
        const pairContract = await getContractInstance('pair', abiContractAddress);
  
        const reservesFunction = pairContract?.methods.getReserves;
        const reserves = await reservesFunction().call();
  
        const reserve0 = BigInt(reserves[0]);
        const reserve1 = BigInt(reserves[1]);
  
        // Adjust the reserve amounts to make the two tokens have the same unit
        const adjustedReserve0 = Number(reserve0) / (10 ** decimalsToken0);
        const adjustedReserve1 = Number(reserve1) / (10 ** decimalsToken1);
        const istoken0 = await getIstokenOne(abiContractAddress, tokenAddress);
        const otherToken = await getOtherToken(abiContractAddress, tokenAddress);
        // Calculate the price, token1/token0
        if (istoken0) {
          return truncateOptimized(adjustedReserve1 / adjustedReserve0);
        } else {
          return truncateOptimized(adjustedReserve0 / adjustedReserve1);
        }
  
      // If the array length is 2, get the price across the transaction
      } else if (abiContractAddressList.length === 2) {
        // First contract address (e.g., USDT and some token pool)
        const abiContractAddress1 = abiContractAddressList[0];
        const pairContract1 = await getContractInstance('pair', abiContractAddress1);
  
        const reservesFunction1 = pairContract1?.methods.getReserves;
        const reserves1 = await reservesFunction1().call();
  
        const reserve0_1 = BigInt(reserves1[0]);
        const reserve1_1 = BigInt(reserves1[1]);
  
        // Adjust the reserve amounts to make the two tokens have the same unit
        const adjustedReserve0_1 = Number(reserve0_1) / (10 ** decimalsToken0);
        const adjustedReserve1_1 = Number(reserve1_1) / (10 ** decimalsToken1);
  
        let pricePool1;
        const istoken0 = await getIstokenOne(abiContractAddress1, tokenAddress);
        // Middle pool token address (Token/USDT)
        const otherToken = await getOtherToken(abiContractAddress1, tokenAddress);
  
        // Calculate the price, token1/token0
        if (istoken0) {
          pricePool1 = adjustedReserve1_1 / adjustedReserve0_1;
        } else {
          pricePool1 = adjustedReserve0_1 / adjustedReserve1_1;
        }
  
        // Second contract address (e.g., cross transaction pool address)
        const abiContractAddress2 = abiContractAddressList[1];
        const pairContract2 = await getContractInstance('pair', abiContractAddress2);
  
        const reservesFunction2 = pairContract2?.methods.getReserves;
        const reserves2 = await reservesFunction2().call();
  
        const reserve0_2 = BigInt(reserves2[0]);
        const reserve1_2 = BigInt(reserves2[1]);
  
        // Adjust the reserve amounts to make the two tokens have the same unit
        const adjustedReserve0_2 = Number(reserve0_2) / (10 ** decimalsToken0);
        const adjustedReserve1_2 = Number(reserve1_2) / (10 ** decimalsToken1);
  
        const istoken0_1 = await getIstokenOne(abiContractAddress2, otherToken);
        let pricePool2;
  
        if (istoken0_1) {
          pricePool2 = adjustedReserve1_2 / adjustedReserve0_2;
        } else {
          pricePool2 = adjustedReserve0_2 / adjustedReserve1_2;
        }
  
        // The final price across pools is the product of the two pool prices
        return truncateOptimized(pricePool1 * pricePool2);
      } else {
        throw new Error('Incorrect length of contract address array');
      }
    } catch (e) {
      console.error(`Failed to get price: ${e}`);
      return 0.0;
    }
  }
 function  truncateOptimized(value) {
    const strValue = value.toString();
  
    // 如果值没有小数部分，直接返回原值
    if (!strValue.includes('.')) {
      return value;
    }
  
    // 找到第一个非零小数位的位置
    const firstNonZeroIndex = strValue.search(/[1-9]/, strValue.indexOf('.') + 1);
  
    if (firstNonZeroIndex === -1) {
      // 如果没有找到非零小数位，直接返回整数部分
      return parseFloat(strValue);
    }
  
    // 需要截取的部分：从第一个非零位开始，最多取4位
    let endIndex = firstNonZeroIndex + 4;
    if (endIndex > strValue.length) {
      endIndex = strValue.length;
    }
    let truncatedValue = strValue.substring(0, endIndex);
  
    // 如果截取的部分有多余的0，删除它们
    truncatedValue = truncatedValue.replace(/0+$/, '');
  
    // 如果结果以小数点结尾，去掉小数点
    if (truncatedValue.endsWith('.')) {
      truncatedValue = truncatedValue.slice(0, -1);
    }
  
    return parseFloat(truncatedValue);
  }

  async function getERC20TokenInfo(tokenAddress) {
    try {
        // 获取 ERC20 合约实例
        const erc20Contract = await getContractInstance('erc20', tokenAddress);

        if (!erc20Contract) {
            throw new Error('无法获取 ERC20 合约实例');
        }

        // 获取代币名称、符号、总供应量和精度
        const [name, symbol, totalSupply, decimals] = await Promise.all([
            erc20Contract.methods.name().call(),
            erc20Contract.methods.symbol().call(),
            erc20Contract.methods.totalSupply().call(),
            erc20Contract.methods.decimals().call(),
        ]);

        return {
            name,
            symbol,
            totalSupply: totalSupply.toString(), // 转换为字符串
            decimals: parseInt(decimals, 10),   // 转换为整数
        };
    } catch (error) {
        console.error('获取 ERC20 代币信息失败:', error.message);
        return null;
    }
}

  async function getERC20TokenBalance(tokenAddress, userAddress) {

    try {
        // 获取代币精度
        const { decimals } = await getERC20TokenInfo(tokenAddress);

        if (decimals === null) {
            throw new Error('无法获取代币精度');
        }

        // 获取 ERC20 合约实例
        const erc20Contract = await getContractInstance('erc20', tokenAddress);

        if (!erc20Contract) {
            showToast('无法获取代币余额')
            throw new Error('无法获取 ERC20 合约实例');

        }

        // 获取代币余额
        const balance = await erc20Contract.methods.balanceOf(userAddress).call();

        // 将 balance 转换为 BigInt 类型
        const bigIntBalance = BigInt(balance);

        // 将 BigInt 转换为字符串并插入小数点
        const balanceString = bigIntBalance.toString();

        // 如果 balance 的位数比 decimals 小，补齐前面的 0
        let readableBalance = '';
        if (balanceString.length <= decimals) {
            readableBalance = '0.' + balanceString.padStart(decimals, '0');
        } else {
            // 在正确的位置插入小数点
            readableBalance = balanceString.slice(0, balanceString.length - decimals) + '.' + balanceString.slice(balanceString.length - decimals);
        }
        // 返回最终的可读余额
        return truncateOptimized(readableBalance);
    } catch (error) {
        console.error(`获取代币余额失败: ${error.message}`);
        return null;
    }
}

export default {
    getCurrentPairPrice,getERC20TokenBalance
   
   
      
     
      
      
}