export const NetConfig = [
  {
    key: 'OKT',
    NetWorkName: 'OKT Chain',
    logoURI: require('@/assets/tokenlogo/OKT.png'),
    Url: 'https://exchainrpc.okex.org/',
    blocsUrl: 'https://www.oklink.com/zh-cn/okc/tx/',
    blocsHashUrl: 'https://www.oklink.com/zh-cn/okc/token/',
    swapConfig: {
      jackRouter: '0xcd4f01b62480926cc4614ea44fb1f1e5f449485e',
      jackFactory: '0x7d72a61f5829b3cb271e6ff4c126f22bd7ffe166',
      weth: '0x8f8526dbfd6e38e3d8307702ca8469bae6c56c15',
    },
    chainId: "0x42",
    TokenBNB: {
      name: 'OKT',
      symbol: 'OKT',
      address: '0x8f8526dbfd6e38e3d8307702ca8469bae6c56c15',
      chainId: 66,
      decimals: 18,
      logoURI: require('@/assets/img/OKT.png'),
    }
  },
  {
    key: 'BNB',
    NetWorkName: 'BNB Chain',
    logoURI:  require('@/assets/tokenlogo/BNBnetwork.png'),
    Url: 'https://bsc-dataseed4.defibit.io/',
    blocsUrl: 'https://bscscan.com/tx/',
    blocsHashUrl: 'https://bscscan.com/token/',
    swapConfig: {
      jackRouter: '0xA3eCBD7E1d95eB6662a64DFb96560AC909f58Bf4',
      jackFactory: '0xF31480d5edf8f49D6525223516EE05cCeB71B4A7',
      weth: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    chainId: "0x38",
    TokenBNB: {
      name: 'BNB',
      symbol: 'BNB',
      address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      chainId: 56,
      decimals: 18,
      logoURI: require('@/assets/tokenlogo/BNB.png'),
    }
  },
  {
    key: 'Polygon',
    NetWorkName: 'Polygon',
    logoURI: require('@/assets/tokenlogo/polygon.png'),
    Url: 'https://polygon.drpc.org',
    blocsUrl: 'https://polygonscan.com/tx/',
    blocsHashUrl: 'https://polygonscan.com/token/',
    swapConfig: {
      jackRouter: '0x4a9ba795bd79d4ffa6126762a4bea757cb2315cb',
      jackFactory: '0x9ccb9a679ac05cb05569ff9916a6d56352d3f587',
      weth: '0xcF1e5136A17b61E12220d3CD4665a707A2690d0a',
    },
    chainId: "0x89",
    TokenBNB: {
      name: 'POL',
      symbol: 'POL',
      address: '0xcF1e5136A17b61E12220d3CD4665a707A2690d0a',
      chainId: 137,
      decimals: 18,
      logoURI: require('@/assets/tokenlogo/polygon.png'),
    }
  },
  {
    key: 'KAD',
    NetWorkName: 'KAD',
    logoURI: require('@/assets/tokenlogo/KAD.png'),
    Url: 'https://rpchttp.kadsea.org',
    blocsUrl: 'https://kadscan.kadsea.org/tx/',
    blocsHashUrl: 'https://kadscan.kadsea.org/token/',
    swapConfig: {
      jackRouter: '0xc8c0a9F0269384be391ae168EAb890de706C3e5A',
      jackFactory: '0xD1b46141fd853fdCB6467A0844A16cf147d56ed7',
      weth: '0xAa8FBD654000E9Fa5c2eDC3179C3C3100ebd53bB',
    },
    chainId: "0x36E",
    TokenBNB: {
      name: 'KAD',
  symbol: 'KAD',
  address: '0xAa8FBD654000E9Fa5c2eDC3179C3C3100ebd53bB',
  chainId: 878,
  decimals: 18,
  logoURI: require('@/assets/tokenlogo/KAD.png'),
    }
  },
]